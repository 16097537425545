import { ProductType } from '@bcf-shared-settings/settings/enums';
import { environmentCommon } from 'configs/environments/environment.common';

const brandName: string = 'lvbetcom';
// eslint-disable-next-line @typescript-eslint/typedef
export const environmentBase = {
  ...environmentCommon,
  productType: ProductType.sportsbook,
  brandI18Name: brandName,
  brandCmsName: 'lvbetcom',
  brandName: 'Lv Bet',
  // sr and me must be here
  langs: ['en', 'sr', 'me', 'uk', 'de', 'br', 'hu'],
  cmsHeadlessEnabled: true
};
