import { Provider } from '@angular/core';
import { abiosConfigName } from '@bcf-v2-configurators/tokens/abios/lvbet/abios-config-name';
import { ABIOS_CONFIG_NAME } from '@bcf-v2-configurators/tokens/abios/token';
import { casinoLink, liveCasinoLink } from '@bcf-v2-configurators/tokens/casino-links/lvbetcom/casino-links';
import { CASINO_FULL_URL, LIVE_CASINO_FULL_URL } from '@bcf-v2-configurators/tokens/casino-links/token';
import { I18N_DESC_LOADER } from '@bcf-v2-configurators/tokens/descs/token';
import {
  editProfileAddressAvailableFieldsFn,
  editProfilePrivacyAndNotificationsAvailableFields
} from '@bcf-v2-configurators/tokens/edit-profile/lvbetcom/edit-profile-available-fields';
import {
  EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
  EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS
} from '@bcf-v2-configurators/tokens/edit-profile/token';
import { footerBrandName } from '@bcf-v2-configurators/tokens/footer/lvbetcom/footer-brand-name';
import { FOOTER_BRAND_NAME } from '@bcf-v2-configurators/tokens/footer/token';
import { ladeskChatConfig } from '@bcf-v2-configurators/tokens/ladesk-chat/lvbetcom/ladesk-chat-config';
import { LADESK_CHAT_CONFIG } from '@bcf-v2-configurators/tokens/ladesk-chat/token';
import { lvbetcomLanguageSwitcherFlagMap } from '@bcf-v2-configurators/tokens/language-switcher/lvbetcom/lvbetcom-language-switcher-flag-map';
import { LANGUAGE_SWITCHER_FLAG_MAP } from '@bcf-v2-configurators/tokens/language-switcher/token';
import { lvisionBetBoosterApiKeys } from '@bcf-v2-configurators/tokens/lvision-bet-booster/lvbetcom/lvision-bet-booster-api-keys';
import { LVISION_BET_BOOSTER_API_KEYS } from '@bcf-v2-configurators/tokens/lvision-bet-booster/token';
import { statscoreVizuConfig } from '@bcf-v2-configurators/tokens/statscore-vizu-config/lvbet/statscore-vizu-config';
import { STATSCORE_VIZU_CONFIG } from '@bcf-v2-configurators/tokens/statscore-vizu-config/token';
import { VIRTUAL_SPORTS_CATEGORIZED } from '@bcf-v2-configurators/tokens/virtual-sports/token';

export const settingsProviders: Provider[] = [
  {
    provide: I18N_DESC_LOADER,
    useValue: () =>
      import(
        /* webpackChunkName: "const-i18n-desc" */ '@bcf-v2-configurators/tokens/descs/lvbetlv/mobile-web/i18n-descs'
        // eslint-disable-next-line @typescript-eslint/typedef
      ).then((mod) => mod.i18nDesc)
  },
  {
    provide: VIRTUAL_SPORTS_CATEGORIZED,
    useValue: () =>
      import(
        /* webpackChunkName: "const-virtual-sports" */ '@bcf-v2-configurators/tokens/virtual-sports/lvbetlv/virtual-sports'
        // eslint-disable-next-line @typescript-eslint/typedef
      ).then((mod) => mod.virtualSportsCategorized)
  },
  {
    provide: LANGUAGE_SWITCHER_FLAG_MAP,
    useValue: lvbetcomLanguageSwitcherFlagMap
  },
  {
    provide: EDIT_PROFILE_PRIVACY_AND_NOTIFICATIONS_AVAILABLE_FIELDS,
    useValue: editProfilePrivacyAndNotificationsAvailableFields
  },
  {
    provide: EDIT_PROFILE_ADDRESS_AVAILABLE_FIELDS,
    useValue: editProfileAddressAvailableFieldsFn
  },
  {
    provide: LADESK_CHAT_CONFIG,
    useValue: ladeskChatConfig
  },
  {
    provide: STATSCORE_VIZU_CONFIG,
    useValue: statscoreVizuConfig
  },
  {
    provide: ABIOS_CONFIG_NAME,
    useValue: abiosConfigName
  },
  {
    provide: CASINO_FULL_URL,
    useValue: casinoLink
  },
  {
    provide: LIVE_CASINO_FULL_URL,
    useValue: liveCasinoLink
  },
  {
    provide: FOOTER_BRAND_NAME,
    useValue: footerBrandName
  },
  {
    provide: LVISION_BET_BOOSTER_API_KEYS,
    useValue: lvisionBetBoosterApiKeys
  }
];
